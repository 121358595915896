import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7d7a6b7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notExist" }
const _hoisted_2 = { style: {"font-size":"40px","font-weight":"bold","color":"#00b4aa","margin-top":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('_404Msg')), 1),
    _createVNode(_component_el_button, {
      type: "text",
      onClick: _ctx.lockLoginOut
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_link, {
          type: "danger",
          underline: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('loginOut')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}